import Matter from "matter-js";

import AnimationImage from "../../assets/images/physics/animation.svg";
import BrandImage from "../../assets/images/physics/brand.svg";
import CreativityImage from "../../assets/images/physics/creativity.svg";
import DevelopmentImage from "../../assets/images/physics/development.svg";
import ExperienceImage from "../../assets/images/physics/experience.svg";
import IdeasImage from "../../assets/images/physics/ideas.svg";
import InspirationImage from "../../assets/images/physics/inspiration.svg";
import PrototypingImage from "../../assets/images/physics/prototyping.svg";
import StartupsImage from "../../assets/images/physics/startups.svg";
import TechnologyImage from "../../assets/images/physics/technology.svg";
import UXImage from "../../assets/images/physics/user-experience.svg";
import UIImage from "../../assets/images/physics/user-interface.svg";
import DesignImage from "../../assets/images/physics/web-design.svg";

let container = document.querySelector(".services__matter-js");

let imageBodies = [
    AnimationImage,
    BrandImage,
    CreativityImage,
    DevelopmentImage,
    ExperienceImage,
    IdeasImage,
    InspirationImage,
    PrototypingImage,
    StartupsImage,
    TechnologyImage,
    UXImage,
    UIImage,
    DesignImage,
];

const THICKNESS = 200;

const RADIUS = 35;

let Engine = Matter.Engine,
    Render = Matter.Render,
    Runner = Matter.Runner,
    Bodies = Matter.Bodies,
    Composite = Matter.Composite;

// create an engine
let engine = Engine.create();

// create a renderer
let render = Render.create({
    element: container,
    engine: engine,
    options: {
        width: container.clientWidth,
        height: container.clientHeight,
        wireframes: false,
        background: "transparent",
    },
});

let animationBody = Bodies.rectangle(200, -800, 226, 74, {
    chamfer: { radius: RADIUS },
    render: {
        sprite: {
            texture: AnimationImage,
            xScale: 1,
            yScale: 1,
            fillStyle: "red",
            strokeStyle: "blue",
        },
    },
});
let brandBody = Bodies.rectangle(400, -900, 188, 74, {
    chamfer: { radius: RADIUS },
    render: {
        sprite: {
            texture: BrandImage,
            xScale: 1,
            yScale: 1,
        },
    },
});
let creativityBody = Bodies.rectangle(300, -1000, 231, 74, {
    chamfer: { radius: RADIUS },
    render: {
        sprite: {
            texture: CreativityImage,
            xScale: 1,
            yScale: 1,
        },
    },
});
let developmentBody = Bodies.rectangle(600, -800, 265, 74, {
    chamfer: { radius: RADIUS },
    render: {
        sprite: {
            texture: DevelopmentImage,
            xScale: 1,
            yScale: 1,
        },
    },
});
let experienceBody = Bodies.rectangle(700, -1000, 240, 74, {
    chamfer: { radius: RADIUS },
    render: {
        sprite: {
            texture: ExperienceImage,
            xScale: 1,
            yScale: 1,
        },
    },
});
let ideasBody = Bodies.rectangle(800, -900, 178, 74, {
    chamfer: { radius: RADIUS },
    render: {
        sprite: {
            texture: IdeasImage,
            xScale: 1,
            yScale: 1,
        },
    },
});
let inspirationBody = Bodies.rectangle(1000, -800, 239, 74, {
    chamfer: { radius: RADIUS },
    render: {
        sprite: {
            texture: InspirationImage,
            xScale: 1,
            yScale: 1,
        },
    },
});
let prototypingBody = Bodies.rectangle(900, -800, 258, 74, {
    chamfer: { radius: RADIUS },
    render: {
        sprite: {
            texture: PrototypingImage,
            xScale: 1,
            yScale: 1,
        },
    },
});
let startupsBody = Bodies.rectangle(800, -800, 219, 74, {
    chamfer: { radius: RADIUS },
    render: {
        sprite: {
            texture: StartupsImage,
            xScale: 1,
            yScale: 1,
        },
    },
});
let technologyBody = Bodies.rectangle(300, -800, 256, 74, {
    chamfer: { radius: RADIUS },
    render: {
        sprite: {
            texture: TechnologyImage,
            xScale: 1,
            yScale: 1,
        },
    },
});
let UXBody = Bodies.rectangle(800, -1000, 295, 74, {
    chamfer: { radius: RADIUS },
    render: {
        sprite: {
            texture: UXImage,
            xScale: 1,
            yScale: 1,
        },
    },
});
let UIBody = Bodies.rectangle(500, -900, 281, 74, {
    chamfer: { radius: RADIUS },
    render: {
        sprite: {
            texture: UIImage,
            xScale: 1,
            yScale: 1,
        },
    },
});
let designBody = Bodies.rectangle(800, -800, 242, 74, {
    chamfer: { radius: RADIUS },
    render: {
        sprite: {
            texture: DesignImage,
            xScale: 1,
            yScale: 1,
        },
    },
});

// create two boxes and a ground
let ground = Bodies.rectangle(
    container.clientWidth / 2,
    container.clientHeight + THICKNESS / 2,
    10000,
    THICKNESS,
    {
        isStatic: true,
        render: { lineWidth: 0, strokeStyle: "#000000" },
    }
);
let rightWall = Bodies.rectangle(
    container.clientWidth + THICKNESS / 2,
    container.clientHeight,
    THICKNESS,
    container.clientHeight * 10,
    {
        isStatic: true,
        render: { lineWidth: 0, strokeStyle: "#000000" },
    }
);
let leftWall = Bodies.rectangle(
    0 - THICKNESS / 2,
    container.clientHeight / 2,
    THICKNESS,
    container.clientHeight * 10,
    {
        isStatic: true,
        render: { lineWidth: 0, strokeStyle: "#000000" },
    }
);

let mouse = Matter.Mouse.create(render.canvas);
let mouseConstraint = Matter.MouseConstraint.create(engine, {
    mouse: mouse,
    constraint: {
        stiffness: 0.2,
        render: {
            visible: false,
        },
    },
});

// add all of the bodies to the world
Composite.add(engine.world, [ground, rightWall, leftWall, mouseConstraint]);
Composite.add(engine.world, [
    animationBody,
    brandBody,
    creativityBody,
    developmentBody,
    experienceBody,
    ideasBody,
    inspirationBody,
    prototypingBody,
    startupsBody,
    technologyBody,
    UXBody,
    UIBody,
    designBody,
]);
// run the renderer
Render.run(render);

// create runner
let runner = Runner.create();

window.addEventListener("resize", () => {
    render.canvas.width = container.clientWidth;
    render.canvas.height = container.clientHeight;

    Matter.Body.setPosition(
        ground,
        Matter.Vector.create(
            container.clientWidth / 2,
            container.clientHeight + THICKNESS / 2
        )
    );
    Matter.Body.setPosition(
        rightWall,
        Matter.Vector.create(
            container.clientWidth + THICKNESS / 2,
            container.clientHeight / 2
        )
    );
});

mouse.element.removeEventListener("mousewheel", mouse.mousewheel);
mouse.element.removeEventListener("DOMMouseScroll", mouse.mousewheel);

let observer = new IntersectionObserver(
    (entries, observer) => {
        entries.forEach((element) => {
            if (element.isIntersecting) {
                Runner.run(runner, engine);
                observer.unobserve(container);
            }
        });
    },
    {
        root: null,
        rootMargin: "0px",
        threshold: 0.1,
    }
);

const initTextEngine = () => observer.observe(container);

export { initTextEngine };
