let callback = function (entries, observer) {
    entries.forEach(function (entry) {
        if (entry.isIntersecting) {
            entry.target.timeline.play();
        } else {
        }
    });
};

let options = {
    threshold: 1,
};

let observer = new IntersectionObserver(callback, options);

let targets = document.querySelectorAll(".text-up_animation__wrapper");

const initObserverAnimation = () => {
    gsap.set(".text-up_animation", { yPercent: 100 });
    let textUpTimeLine = gsap.timeline({ paused: true });
    textUpTimeLine.to(".text-up_animation", {
        yPercent: 0,
        duration: 1,
    });
    targets.forEach((target) => {
        observer.observe(target);
        target.timeline = textUpTimeLine;
    });
};
export { initObserverAnimation };
