import gsap from "../libs/gsap.min";
import ScrollTrigger from "../libs/ScrollTrigger.min";

import secondPhoto from "../../assets/images/animation/2.jpg";
import thirdPhoto from "../../assets/images/animation/3.jpg";
import fourthPhoto from "../../assets/images/animation/4.jpg";
import fifthPhoto from "../../assets/images/animation/5.jpg";

gsap.registerPlugin(ScrollTrigger);

const vw = (coef) => window.innerWidth * (coef / 100);
const vh = (coef) => window.innerHeight * (coef / 100);

let clamp = gsap.utils.clamp(1, 5);

gsap.set(".main__description-span", { opacity: 0.2 });

ScrollTrigger.matchMedia({
    "(min-width: 1025px)": () => {
        ScrollTrigger.create({
            onUpdate: (self) => {
                let dump = clamp(Math.abs(self.getVelocity() / 200));
                document.querySelector(".main__video").playbackRate = dump;
            },
        });

        let mainTimeLine = gsap.timeline({
            scrollTrigger: {
                trigger: ".main-section",
                start: "top top",
                end: () => 4000,
                pin: true,
                scrub: true,
            },
        });

        let textTimeLine = gsap.timeline({
            scrollTrigger: {
                trigger: ".main-section",
                start: "top top",
                end: () => 5000,
                pin: true,
                scrub: true,
            },
        });

        gsap.set(".main-section", {
            height: vh(100),
        });
        gsap.set(".main-section_second-content", {
            position: "absolute",
            bottom: vh(20),
            columnGap: "30vw",
        });
        gsap.set(".main__image_wrapper", {
            xPercent: -100,
            opacity: 0,
        });
        gsap.set(".main-section_second-content", {
            columnGap: "60vw",
            zIndex: -1,
        });
        gsap.set(".main__image_wrapper", { maxHeight: "30vh" });
        gsap.set(".main__description", { opacity: 0 });
        mainTimeLine.to(".main__text", {
            fontSize: vw(8.35),
            maxWidth: vw(65),
            y: vw(-10.41668),
        });
        mainTimeLine.to(
            ".main__name",
            {
                fontSize: vw(8.35),
                maxWidth: vw(80),
                y: vw(7.8125),
                x: 0,
            },
            "<"
        );
        mainTimeLine.to(
            ".main__svg",
            {
                maxHeight: vw(6),
            },
            "<"
        );
        mainTimeLine.to(
            ".main__text-span",
            {
                marginLeft: 0,
            },
            "<"
        );
        mainTimeLine.to(
            ".main__video",
            {
                y: vw(-7.8125),
                x: vw(31.25),
                maxWidth: vw(27.604),
            },
            "<"
        );
        mainTimeLine.to(
            ".main__name-span",
            {
                marginLeft: vw(-11.979),
            },
            "<"
        );
        mainTimeLine.to(
            ".main__image_wrapper",
            {
                xPercent: 0,
                opacity: 1,
            },
            "<"
        );
        mainTimeLine.to(".main-section_first-content", {
            x: "100vw",
        });
        mainTimeLine.to(".main__image_wrapper", { maxHeight: "70vh" }, "<0.2");
        mainTimeLine.to(".main-section_second-content", { columnGap: "20vw", zIndex: 1 }, "<");
        mainTimeLine.to(".main__description", { opacity: 1 }, "<");

        textTimeLine.to(
            ".main__description-span",
            {
                opacity: 1,
                stagger: 0.2,
            },
            ">40"
        );
        textTimeLine.to(
            ".main__image",
            {
                attr: {
                    src: secondPhoto,
                },
                ease: "steps(1)",
            },
            "<5"
        );
        textTimeLine.to(
            ".main__image",
            {
                attr: {
                    src: thirdPhoto,
                },
                ease: "steps(1)",
            },
            "<5"
        );
        textTimeLine.to(
            ".main__image",
            {
                attr: {
                    src: fourthPhoto,
                },
                ease: "steps(1)",
            },
            "<5"
        );
        textTimeLine.to(
            ".main__image",
            {
                attr: {
                    src: fifthPhoto,
                },
                ease: "steps(1)",
            },
            "<5"
        );
    },
    "(min-width: 769px) and (min-height: 900px)": () => {
        let scrollTimeLine = gsap.timeline({
            scrollTrigger: {
                trigger: ".services__content",
                start: `top 15%`,
                end: () => document.querySelector(".services__content").scrollWidth + "px",
                pin: true,
                scrub: true,
            },
        });

        scrollTimeLine.to(".services-section", {
            x: () => document.querySelector(".services__content").clientWidth - document.querySelector(".services__content").scrollWidth,
            ease: "none",
        });
    },
    "(min-width: 769px) and (max-height: 900px)": () => {
        let scrollTimeLine = gsap.timeline({
            scrollTrigger: {
                trigger: ".services__content",
                start: `top 5%`,
                end: () => document.querySelector(".services__content").scrollWidth + "px",
                pin: true,
                scrub: true,
            },
        });
        scrollTimeLine.to(".services-section", {
            x: () => document.querySelector(".services__content").clientWidth - document.querySelector(".services__content").scrollWidth,
            ease: "none",
        });
    },
});
