import "./modules/IndexScrollAnimations";
import { initScrollSmoother } from "./modules/ScrollSmoother";
import { timeInit } from "./modules/Time";
import { initProgressBar } from "./modules/ProgressBar";
import { initCursor } from "./modules/Cursor";
import { initTextEngine } from "./modules/TextMatter";
import { initAttractHover } from "./modules/AttractOnHover";
import { init3DHoverEffect } from "./modules/3DHoverEffect";
import { initObserverAnimation } from "./modules/ObserverAnimation";
import { initForm } from "./modules/Form";
import { initMenu } from "./modules/Menu";
import { initScrollTo } from "./modules/ScrollTo";

const init = () => {
    initScrollSmoother();
    timeInit();
    initProgressBar();
    initCursor();
    initTextEngine();
    initAttractHover();
    init3DHoverEffect();
    initObserverAnimation();
    initForm();
    initMenu();
    initScrollTo();
};

init();
